import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { RequestedSummary } from "../../components/RequestedSummary";
import logo from "../../assets/images/logo-drsaude.png";
import mobileThirdStep from "../../assets/images/third-step-mobile.png";
import googlePlay from "../../assets/images/google-play.png";
import appleStore from "../../assets/images/app-store.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import successIcon from "../../assets/images/checked.png";
import HttpsIcon from "@mui/icons-material/Https";
import Slide from "@material-ui/core/Slide";
import { useStyles } from "./styles";
import theme from "../../theme";
import { Paper } from "@material-ui/core";
import { DesktopInformations } from "../../components/DesktopInformations";
import { data } from "../../mocks/planInformations";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation } from "react-router-dom";
import api from "../../services/api";
import { usePlanContext } from "../../context";

export function Payment() {
  const classes = useStyles();
  const { state } = useLocation();
  const { data, transaction } = state;
  const {
    planNameSelected,
    setPlanNameSelected,
    dependentsSelected,
    setDependentsSelected,
    valueSelected,
    setValueSelected,
    paymentType,
    setPaymentType,
  } = usePlanContext();

  const [plans, setPlans] = useState<any[]>([]);
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("selectedPlan") || "{}");
  
    if (storedData.planNameSelected) {
      setPlanNameSelected(storedData.planNameSelected);
      setDependentsSelected(storedData.dependents || "");
      setValueSelected(storedData.value || "");
      setPaymentType(storedData.paymentType || "");
    }
  }, []);
  
  useEffect(() => {
    const getPlansOptions = async () => {
      try {
        const response = await api.get("/api/user/onboarding/list/plans");
        setPlans(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getPlansOptions();
  }, []);
  
  useEffect(() => {
    if (plans.length > 0 && !planNameSelected) {
      setPlanNameSelected(plans[0].name);
      setDependentsSelected(plans[0].registrations);
      setValueSelected(plans[0].value_formatted);
    }
  }, [plans, planNameSelected]);
  
  const [openSummary, setOpenSummary] = useState<boolean>(false);
  return (
    <>
      {openSummary ? (
        <Slide direction="right" in mountOnEnter unmountOnExit>
          <Paper elevation={4}>
            <RequestedSummary
              setOpenSummary={setOpenSummary}
              holderName={data?.principal?.name}
              holderCpf={data?.principal?.cpf}
              holderPhone={data?.principal?.phone}
              holderEmail={data?.principal?.email}
              holderAddress={data?.principal?.address?.street}
              holderCep={data?.principal?.address?.zip_code}
              dependents={String(dependentsSelected) ?? ""}
              value={valueSelected ?? ""}
              paymentType={paymentType}
              planNameSelected={planNameSelected ?? ""}
            />
          </Paper>
        </Slide>
      ) : (
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Box className={classes.wrapperInfo}>
              <Box className={classes.containerSteps}>
                <Box className={classes.containerCircle}>
                  <Box className={classes.circleOutlined}>
                    <Box>
                      <CheckIcon
                        style={{ color: theme.palette.primary.main }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    className={classes.textStep}
                    style={{ color: "#908F8F", fontWeight: 500 }}
                  >
                    Cadastro
                  </Typography>
                </Box>
                <Box className={classes.containerCircle}>
                  <Box className={classes.circleOutlined}>
                    <Box>
                      <CheckIcon
                        style={{ color: theme.palette.primary.main }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    className={classes.textStep}
                    style={{ color: "#908F8F", fontWeight: 500 }}
                  >
                    Forma de pagamento
                  </Typography>
                </Box>
                <Box className={classes.containerCircle}>
                  <Box className={classes.circle}>
                    <Typography className={classes.circleText}>3</Typography>
                  </Box>
                  <Typography className={classes.textStep}>
                    Comprovante de ativação
                  </Typography>
                </Box>
              </Box>
              <img src={logo} alt="Logo Dr Saude" className={classes.logo} />
              <Box
                className={classes.retangleOrder}
                onClick={() => setOpenSummary(true)}
              >
                <Typography className={classes.textOrder}>
                  Resumo do pedido
                </Typography>
                <KeyboardArrowDownIcon
                  style={{ color: theme.palette.text.primary }}
                />
              </Box>
            </Box>
            <Box className={classes.containerEnvinroment}>
              <Typography className={classes.textSecurity}>
                <HttpsIcon
                  style={{ width: "16px", color: theme.palette.text.secondary }}
                />
                ambiente seguro
              </Typography>
            </Box>
            <Box className={classes.wrapperStep}>
              <img src={mobileThirdStep} alt="Etapa da tela" />
            </Box>
          </Box>
          <Typography className={classes.title}>
            Comprovante de ativação
          </Typography>
          <Box className={classes.containerColumns}>
            <Box className={classes.box}>
              <Typography className={classes.boxTitle}>
                Comprovante de ativação
              </Typography>
              <Box className={classes.separator} />
              <Box className={classes.content}>
                <img src={successIcon} alt="Icone de sucesso" />
                <Typography className={classes.contentTitle}>
                  Ativação do plano em processamento.
                </Typography>
                <Typography className={classes.contentSubtitle}>
                  Código da transação: {transaction ?? ""}
                </Typography>
                <Typography className={classes.contentSubtitle}>
                  Enviamos em seu e-mail de cadastro todas as informações
                  necessárias para a ativação do plano.
                </Typography>
                <Typography
                  className={classes.contentSubtitle}
                  style={{ color: "#000" }}
                >
                  Em breve nossa equipe fará contato com você através do seu
                  telefone de contato
                </Typography>
                <Typography className={classes.contentSubtitle}>
                  aproveite e baixa o nosso app nas lojas digitais
                </Typography>
                <Box className={classes.containerImages}>
                  <img src={googlePlay} alt="" />
                  <img src={appleStore} alt="" />
                </Box>
              </Box>
            </Box>
            <DesktopInformations
              setOpenSummary={setOpenSummary}
              holderName={data?.principal?.name ?? ""}
              holderCpf={data?.principal?.cpf ?? ""}
              holderPhone={data?.principal?.phone ?? ""}
              holderEmail={data?.principal?.email ?? ""}
              holderAddress={data?.principal?.address?.street ?? ""}
              holderCep={data?.principal?.address?.zip_code ?? ""}
              dependents={String(dependentsSelected) ?? ""}
              value={valueSelected ?? ""}
              paymentType={paymentType}
              planNameSelected={planNameSelected ?? ""}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export function PhoneMask(e: any) {
  let value = e;
  if (!value) return ""

  value = value.replace(/\D/g, "");
  value = value
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
    .replace(/(-\d{4})\d+?$/, "$1");
  return value;
}

export function CpfMask(e: any) {
  let value = e;
  value = value?.replace(/\D/g, "");
  value = value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
  return value;
}

export function dateMask(value: string | undefined): string {
  if (!value) return ""; 
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})\d+?$/, "$1");
}


export function formatCreditCardNumber(cardNumber: any) {
  const cleanedNumber = cardNumber.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
  const chunks = cleanedNumber.match(/.{1,4}/g); // Divide em grupos de 4 dígitos

  if (chunks) {
    return chunks.join(' ');
  } else {
    return cleanedNumber;
  }
}

export function FormatMoneyDynamic(v: any) {
  if (typeof v !== 'string') {
    v = String(v);
  }
  v = v.replace('.', '').replace(',', '').replace(/\D/g, '')

  const options = { minimumFractionDigits: 2 }
  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(v) / 100
  )

  return 'R$ ' + result
}

export function formatCEP(cep: any) {
  cep = cep.replace(/\D/g, '')

  cep = cep.replace(/^(\d{5})(\d{3})$/, '$1-$2')

  return cep
}


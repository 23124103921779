import { Box, Typography } from "@mui/material";
import { useStyles } from "./style";
import twoPeople from "../../assets/images/duas-pessoas.png";
import people from "../../assets/images/uma-pessoa.png";
import checked from "../../assets/images/checked.svg";
import React from "react";

interface CardProps {
  dependents: number;
  value: string;
  dependentsSelected: number;
  setDependentsSelected: (value: number) => void;
  setValueSelected: (value: string) => void;
  name: string;
  handlePlanSelection: any;
  planCode: string;
  handleNameSelection: any;
  selectedPlanCode: any;
  setSelectedPlanCode: any;
}

export function Card({
  dependents,
  value,
  dependentsSelected,
  setDependentsSelected,
  setValueSelected,
  name,
  handlePlanSelection,
  planCode,
  handleNameSelection,
  selectedPlanCode,
  setSelectedPlanCode,
}: CardProps) {
  const classes = useStyles();

  const isChecked = planCode === selectedPlanCode;

  const changeImage = () => {
    const images = [people];
    let remainingDependents = dependents;

    while (remainingDependents > 0) {
      if (remainingDependents >= 2) {
        images.push(twoPeople);
        remainingDependents -= 2;
      } else if (remainingDependents === 1) {
        images.push(people);
        remainingDependents -= 1;
      }
    }

    return images;
  };

  return (
    <Box
      className={classes.container}
      onClick={() => {
        setDependentsSelected(dependents);
        setValueSelected(value);
        handlePlanSelection(planCode);
        handleNameSelection(name);
        setSelectedPlanCode(planCode);
      }}
      style={{
        boxShadow: isChecked ? "0px 0px 10px 0px rgba(0, 0, 0, 0.60)" : "none",
        minWidth: "250px",
        gap: "16px",
      }}
    >
      <Box className={classes.content}>
        <Box className={classes.topInformation}>
          {isChecked ? (
            <img src={checked} alt="icone de selecionado" />
          ) : (
            <Box />
          )}
          <Box display="flex" alignItems="center" gap="4px">
            {changeImage().map((imgSrc, index, arr) => (
              <React.Fragment key={index}>
                <img
                  src={imgSrc}
                  alt={`imagem ${index}`}
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "contain",
                  }}
                />
                {index < arr.length - 1 && (
                  <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
                    +
                  </Typography>
                )}
              </React.Fragment>
            ))}
          </Box>
        </Box>
        <Box>
          <Typography className={classes.title}>{name}</Typography>
          <Typography className={classes.information}>{value}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useStyles } from "./styles";
import { TextField, Typography } from "@mui/material";
import { formatCreditCardNumber } from "../../utils/masks";
import flagCards from "../../assets/images/payments.png";

export default function TabsMethodPayment({
  payment,
  setPayment,
  value,
  handleChange,
}: any) {
  const classes = useStyles();

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ overflowX: "auto", whiteSpace: "nowrap" }}
      >
        <Tab
          value="credit_card"
          label="Cartão de crédito"
          sx={{ fontSize: "18px", fontWeight: "bold" }}
        />
        <Tab
          value="boleto"
          label="Boleto Bancário"
          sx={{ fontSize: "18px", fontWeight: "bold" }}
        />
        <Tab
          value="cpfl"
          label="Pagamento CPFL"
          sx={{ fontSize: "18px", fontWeight: "bold" }}
        />
      </Tabs>

      {value === "credit_card" && (
        <Box>
          <Typography className={classes.boxTitle}>
            Informações do Cartão de Crédito
          </Typography>
          <Box className={classes.separator} />
          <label className={classes.label}>Nome titular do cartão</label>
          <TextField
            name="name"
            id="outlined-basic"
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginTop: "5px", marginBottom: "20px" }}
            value={payment?.card_holder_name}
            onChange={(e) =>
              setPayment({
                ...payment,
                card_holder_name: e.target.value,
              })
            }
          />
          <label className={classes.label}>Número do cartão</label>
          <TextField
            name="cardNumber"
            id="outlined-basic"
            variant="outlined"
            size="small"
            fullWidth
            value={
              formatCreditCardNumber(payment?.card_number) ??
              payment?.card_number
            }
            onChange={(e) => {
              setPayment({ ...payment, card_number: e.target.value });
            }}
            inputProps={{ maxLength: 19 }}
          />
          <Box className={classes.containerCards}>
            <img
              alt="Bandeiras do cartao de credito"
              src={flagCards}
              className={classes.imgCards}
            />
          </Box>
          <Box className={classes.divRow}>
            <Box className={classes.labelAndInput}>
              <label className={classes.label}>Mês de expiração</label>
              <TextField
                name="expired"
                id="outlined-basic"
                variant="outlined"
                size="small"
                fullWidth
                sx={{ marginTop: "5px", marginBottom: "20px" }}
                value={payment?.card_month_expiration}
                onChange={(e) =>
                  setPayment({
                    ...payment,
                    card_month_expiration: e.target.value,
                  })
                }
                inputProps={{ maxLength: 2 }}
              />
            </Box>
            <Box className={classes.labelAndInput}>
              <label className={classes.label}>Ano de expiração</label>
              <TextField
                name="expired"
                id="outlined-basic"
                variant="outlined"
                size="small"
                fullWidth
                sx={{ marginTop: "5px", marginBottom: "20px" }}
                value={payment?.card_year_expiration}
                onChange={(e) =>
                  setPayment({
                    ...payment,
                    card_year_expiration: e.target.value,
                  })
                }
                inputProps={{ maxLength: 2 }}
              />
            </Box>
            <Box className={classes.labelAndInput}>
              <label className={classes.label}>CVV</label>
              <TextField
                name="cvv"
                id="outlined-basic"
                variant="outlined"
                size="small"
                fullWidth
                sx={{ marginTop: "5px", marginBottom: "20px" }}
                value={payment?.card_cvv}
                onChange={(e) =>
                  setPayment({ ...payment, card_cvv: e.target.value })
                }
                inputProps={{ maxLength: 4 }}
              />
            </Box>
          </Box>
        </Box>
      )}

      {value === "boleto" && (
        <Box>
          <Typography className={classes.descriptionTile}>
            O pagamento via boleto pode levar até 72 horas para ser compensado,
            e a ativação do plano será realizada automaticamente após a
            confirmação.
          </Typography>
        </Box>
      )}

      {value === "cpfl" && (
        <Box>
          <Typography className={classes.descriptionTile}>
            Para pagamentos via CPFL, a conclusão cadastral será feita junto à
            central Dr. Saúde, sendo obrigatória a apresentação presencial dos
            seguintes documentos: cópia da conta atual, documento com foto do
            titular da conta e autorização de cobrança da CPFL.
          </Typography>
        </Box>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import logo from "../../assets/images/logo-drsaude.png";
import mobileSecondStep from "../../assets/images/second-step-mobile.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HttpsIcon from "@mui/icons-material/Https";
import { useStyles } from "./styles";
import theme from "../../theme";
import { AccordionDependent } from "../../components/AccordionOne";
import { DesktopInformations } from "../../components/DesktopInformations";
import { Paper } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { RequestedSummary } from "../../components/RequestedSummary";
import CheckIcon from "@mui/icons-material/Check";
import { Card } from "../../components/Card";
import { useLocation, useNavigate } from "react-router-dom";
import UsersService from "../../services/UsersService";
import Swal from "sweetalert2";
import TabsMethodPayment from "../../components/TabsMethodPayment.tsx";
import api from "../../services/api";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";

export interface IPlan {
  name: string;
  plan_code: string;
  value: number;
  registrations: number;
  value_formatted: string;
}

interface IDependent {
  name: string;
  phone: string;
  email: string;
  cpf: string;
  birthday: string;
  address: {
    zip_code: string;
    state: string;
    city: string;
    street: string;
    number: string;
    neighborhood: string;
    complement: string;
  };
}

export function Registration() {
  const classes = useStyles();
  const { state } = useLocation();
  const { data, manager_code } = state;
  const [openSummary, setOpenSummary] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dependentsSelected, setDependentsSelected] = useState<number>(0);
  const [valueSelected, setValueSelected] = useState<string>("29.90");
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const navigate = useNavigate();
  const [value, setValue] = useState("credit_card");
  const [planNameSelected, setPlanNameSelected] = useState<string>("");
  const [planCodeSelected, setPlanCodeSelected] = useState<string>("plan0");
  const [validationForm, setValidationForm] = useState<boolean>(false);

  const [payment, setPayment] = useState({
    card_holder_name: "",
    card_brand: "",
    card_cvv: "",
    card_year_expiration: "",
    card_month_expiration: "",
    card_number: "",
    plan_code: "",
    manager_code: manager_code,
    payment_type: "credit_card",
  });

  const [dependents, setDependents] = useState<IDependent[]>([]);
  useEffect(() => {
    if (plans.length > 0) {
      setPlanNameSelected(plans[0].name);
      setDependentsSelected(plans[0].registrations);
      setValueSelected(plans[0].value_formatted);
      setValidationForm(plans[0].registrations > 0);
    }
  }, [plans]);

  const validationCard = !hasEmptyFields(payment);
  const changeFinalBody = () => {
    if (!data) return null;

    const paymentBody =
      payment?.payment_type === "cpfl" || payment?.payment_type === "boleto"
        ? {
            payment_type: payment?.payment_type,
            plan_code: planCodeSelected,
            manager_code: manager_code,
            card_holder_name: null,
            card_brand: null,
            card_cvv: null,
            card_year_expiration: null,
            card_month_expiration: null,
            card_number: null,
          }
        : {
            payment_type: payment?.payment_type,
            card_holder_name: payment?.card_holder_name,
            card_brand: payment?.card_brand,
            card_cvv: payment?.card_cvv,
            card_year_expiration: payment?.card_year_expiration,
            card_month_expiration: payment?.card_month_expiration,
            card_number: payment?.card_number?.replace(/[^0-9s]/g, ""),
            plan_code: planCodeSelected,
            manager_code: manager_code,
          };

    const dependentBody = dependents.map((dependent) => ({
      name: dependent.name || "",
      phone: dependent.phone ? dependent.phone.replace(/[^0-9s]/g, "") : "",
      email: dependent.email || "",
      cpf: dependent.cpf ? dependent.cpf.replace(/[^0-9s]/g, "") : "",
      birthday: dependent.birthday
        ? dependent.birthday.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1")
        : "",
      address: {
        zip_code: dependent.address?.zip_code
          ? dependent.address.zip_code.replace(/[^0-9s]/g, "")
          : "",
        state: dependent.address?.state || "",
        city: dependent.address?.city || "",
        street: dependent.address?.street || "",
        number: dependent.address?.number || "",
        neighborhood: dependent.address?.neighborhood || "",
        complement: dependent.address?.complement || "",
      },
      plan_code: planCodeSelected,
    }));

    const body = {
      principal: {
        name: data?.name,
        phone: data?.phone.replace(/[^0-9s]/g, ""),
        email: data?.email,
        cpf: data?.cpf.replace(/[^0-9s]/g, ""),
        birthday: data?.birthday.replace(
          /(\d{2})\/(\d{2})\/(\d{4})/,
          "$3-$2-$1"
        ),
        address: {
          zip_code: data?.address?.zip_code.replace(/[^0-9s]/g, ""),
          state: data?.address?.state,
          city: data?.address?.city,
          street: data?.address?.street,
          number: data?.address?.number,
          neighborhood: data?.address?.neighborhood,
          complement: data?.address?.complement,
        },
        payment: paymentBody,
      },
      dependentes: dependentBody,
    };

    return body;
  };

  function hasEmptyFields(data: any) {
    for (const key in data) {
      if (
        key !== "manager_code" &&
        key !== "plan_code" &&
        key !== "card_brand" &&
        typeof data[key] === "string" &&
        data[key].trim() === ""
      ) {
        return true;
      }
    }
    return false;
  }

  function identifyCardBrand(cardNumber: any) {
    const cardNumberString = cardNumber?.toString();
    if (cardNumberString.startsWith("4")) {
      setPayment({ ...payment, card_brand: "visa" });
      return;
    } else if (cardNumberString.startsWith("5")) {
      setPayment({ ...payment, card_brand: "MasterCard" });
      return;
    } else if (cardNumberString.match(/^3[47]/)) {
      setPayment({ ...payment, card_brand: "American Express" });
      return;
    } else if (cardNumberString.startsWith("6")) {
      setPayment({ ...payment, card_brand: "Discover" });
      return;
    } else {
      setPayment({ ...payment, card_brand: "Bandeira desconhecida" });
      return;
    }
  }

  useEffect(() => {
    const validateBody = () => {
      const isPaymentValid =
        payment?.payment_type === "boleto" || payment?.payment_type === "cpfl"
          ? true
          : validationCard;
      const isFormValid = dependentsSelected > 0 ? validationForm : true;

      if (
        acceptTerms &&
        dependentsSelected >= 0 &&
        isPaymentValid &&
        isFormValid
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };

    validateBody();
  }, [
    dependentsSelected,
    validationForm,
    acceptTerms,
    validationCard,
    payment?.payment_type,
  ]);

  useEffect(() => {
    if (payment?.card_number !== "") {
      identifyCardBrand(payment?.card_number);
    }
  }, [payment?.card_number]);

  async function handleSubmit() {
    setLoading(true);
    const body = await changeFinalBody();
    try {
      const { data } = await UsersService.create(body);
      if (data.status === true) {
        navigate("/pagamento", {
          state: { data: body, transaction: data?.transaction_code },
        });
      }
    } catch (error: any) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showConfirmButton: false,
        timer: 5000,
      });
    } finally {
      setLoading(false);
    }
  }

  const handleCheckboxChange = () => {
    setAcceptTerms(!acceptTerms);
  };

  const getPlansOptions = async () => {
    try {
      const data = await api.get("/api/user/onboarding/list/plans");
      setPlans(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPlansOptions();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setPayment({ ...payment, payment_type: newValue });
  };

  const handlePlanSelection = (planCode: string) => {
    setPlanCodeSelected(planCode);

    const selectedPlan = plans.find((plan) => plan.plan_code === planCode);
    if (selectedPlan) {
      const dependentArray: IDependent[] = Array.from(
        { length: selectedPlan.registrations },
        () => ({
          name: "",
          phone: "",
          email: "",
          cpf: "",
          birthday: "",
          address: {
            zip_code: "",
            state: "",
            city: "",
            street: "",
            number: "",
            neighborhood: "",
            complement: "",
          },
        })
      );
      setDependents(dependentArray);
    }
  };

  const handleNameSelection = (planName: string) => {
    setPlanNameSelected(planName);
  };

  useEffect(() => {
    const data = {
      dependents: String(dependentsSelected) ?? "",
      value: valueSelected ?? "",
      paymentType: payment?.payment_type ?? "",
      planNameSelected: planNameSelected ?? "",
    };

    localStorage.setItem("selectedPlan", JSON.stringify(data));
  }, [
    dependentsSelected,
    valueSelected,
    payment?.payment_type,
    planNameSelected,
  ]);

  return (
    <>
      {openSummary ? (
        <Slide direction="right" in mountOnEnter unmountOnExit>
          <Paper elevation={4}>
            <RequestedSummary
              setOpenSummary={setOpenSummary}
              holderName={data?.name ?? ""}
              holderCpf={data?.cpf ?? ""}
              holderPhone={data?.phone ?? ""}
              holderEmail={data?.email ?? ""}
              holderAddress={data?.address.street ?? ""}
              holderCep={data?.address.zip_code ?? ""}
              dependents={String(dependentsSelected) ?? ""}
              value={valueSelected ?? ""}
              paymentType={payment?.payment_type}
              planNameSelected={planNameSelected ?? ""}
            />
          </Paper>
        </Slide>
      ) : (
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Box className={classes.wrapperInfo}>
              <Box className={classes.containerSteps}>
                <Box className={classes.containerCircle}>
                  <Box className={classes.circleOutlined}>
                    <Box>
                      <CheckIcon
                        style={{ color: theme.palette.primary.main }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    className={classes.textStep}
                    style={{ color: "#908F8F", fontWeight: 500 }}
                  >
                    Cadastro
                  </Typography>
                </Box>
                <Box className={classes.containerCircle}>
                  <Box className={classes.circle}>
                    <Typography className={classes.circleText}>2</Typography>
                  </Box>
                  <Typography className={classes.textStep}>
                    Forma de pagamento
                  </Typography>
                </Box>
                <Box className={classes.containerCircle}>
                  <Box className={classes.circleOutlined}>
                    <Typography className={classes.circleTextOutlined}>
                      3
                    </Typography>
                  </Box>
                  <Typography
                    className={classes.textStep}
                    style={{ color: "#908F8F", fontWeight: 500 }}
                  >
                    Comprovante de ativação
                  </Typography>
                </Box>
              </Box>
              <img src={logo} alt="Logo Dr Saude" className={classes.logo} />
              <Box
                className={classes.retangleOrder}
                onClick={() => setOpenSummary(true)}
              >
                <Typography className={classes.textOrder}>
                  Resumo do pedido
                </Typography>
                <KeyboardArrowDownIcon
                  style={{ color: theme.palette.text.primary }}
                />
              </Box>
            </Box>
            <Box className={classes.containerEnvinroment}>
              <Typography className={classes.textSecurity}>
                <HttpsIcon
                  style={{ width: "16px", color: theme.palette.text.secondary }}
                />
                ambiente seguro
              </Typography>
            </Box>
            <Box className={classes.wrapperStep}>
              <img src={mobileSecondStep} alt="Etapa da tela" />
            </Box>
          </Box>
          <Box className={classes.containerColumns}>
            <Box className={classes.box}>
              <TabsMethodPayment
                payment={payment}
                setPayment={setPayment}
                value={value}
                handleChange={handleChange}
              />
              <Box className={classes.planOptions}>
                <Typography className={classes.boxTitle}>
                  Opções de planos
                </Typography>
                <Box className={classes.separator} />
                <Box className={classes.containerCardsDependents}>
                  <Swiper
                    modules={[Scrollbar]}
                    slidesPerView={3}
                    scrollbar={{ draggable: true }}
                    style={{
                      paddingBottom: "30px",
                      paddingTop: "10px",
                      display: "flex",
                    }}
                    direction="horizontal"
                    breakpoints={{
                      1130: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      1470: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                    }}
                  >
                    {plans?.map((plan) => (
                      <SwiperSlide
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Card
                          dependents={plan?.registrations}
                          value={plan?.value_formatted}
                          dependentsSelected={dependentsSelected}
                          setDependentsSelected={setDependentsSelected}
                          setValueSelected={setValueSelected}
                          name={plan?.name}
                          handlePlanSelection={handlePlanSelection}
                          handleNameSelection={handleNameSelection}
                          planCode={plan?.plan_code}
                          selectedPlanCode={planCodeSelected}
                          setSelectedPlanCode={setPlanCodeSelected}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Box>
                <Box className={classes.containerCardsDependentsMobile}>
                  <Swiper
                    style={{
                      paddingBottom: "30px",
                      paddingTop: "10px",
                      display: "flex",
                    }}
                    modules={[Scrollbar]}
                    slidesPerView={1}
                    spaceBetween={10}
                    scrollbar={{ draggable: true }}
                    direction="horizontal"
                  >
                    {plans?.map((plan) => (
                      <SwiperSlide
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Card
                          dependents={plan?.registrations}
                          value={plan?.value_formatted}
                          dependentsSelected={dependentsSelected}
                          setDependentsSelected={setDependentsSelected}
                          setValueSelected={setValueSelected}
                          name={plan?.name}
                          handlePlanSelection={handlePlanSelection}
                          handleNameSelection={handleNameSelection}
                          planCode={plan?.plan_code}
                          selectedPlanCode={planCodeSelected}
                          setSelectedPlanCode={setPlanCodeSelected}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Box>

                <Box className={classes.containerAccordions}>
                  {dependents.length > 0 &&
                    dependents.map((dependent, index) => (
                      <AccordionDependent
                        key={index}
                        index={index}
                        body={dependent}
                        setBody={(newBody: Partial<IDependent>) => {
                          setDependents((prevDependents) =>
                            prevDependents.map((dep, i) =>
                              i === index
                                ? {
                                    ...dep,
                                    ...newBody,
                                    address: {
                                      ...dep.address,
                                      ...newBody.address,
                                    },
                                  }
                                : dep
                            )
                          );
                        }}
                        setValidationFormOne={setValidationForm}
                        searchPostalCode={async () => {
                          if (
                            dependent.address?.zip_code &&
                            dependent.address.zip_code.length === 8
                          ) {
                            try {
                              const response = await fetch(
                                `https://viacep.com.br/ws/${dependent.address.zip_code}/json/`
                              );
                              const data = await response.json();

                              if (!data.erro) {
                                setDependents((prevDependents) =>
                                  prevDependents.map((dep, i) =>
                                    i === index
                                      ? {
                                          ...dep,
                                          address: {
                                            ...dep.address,
                                            zip_code: data.cep,
                                            street: data.logradouro,
                                            neighborhood: data.bairro,
                                            city: data.localidade,
                                            state: data.uf,
                                          },
                                        }
                                      : dep
                                  )
                                );
                              }
                            } catch (error) {
                              console.error("Erro ao buscar o CEP:", error);
                            }
                          }
                        }}
                      />
                    ))}
                </Box>

                <Box className={classes.containerCheckbox}>
                  <Checkbox
                    checked={acceptTerms}
                    onChange={handleCheckboxChange}
                  />
                  <label>Li e aceito o </label>
                  <a
                    href="https://doutorsaudesorocaba.com.br/wp-content/uploads/2025/02/Contrato-Vialux_041219.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    contrato de aquisição dos serviços
                  </a>
                </Box>

                <Box className={classes.containerCheckbox}>
                  <Checkbox checked={true} />
                  <label>Retirada do cartão Dr. Saúde</label>
                </Box>

                <Box className={classes.containerCardDrSaude}>
                  <p>
                    <strong>Retirada na Central:</strong> O cliente deverá
                    comparecer ao local Doutor Saúde, localizado na Avenida
                    Presidente Juscelino Kubitschek de Oliveira, 625 - Centro,
                    Sorocaba, nos seguintes horários de atendimento:
                  </p>

                  <strong>
                    <li>🕒 Segunda a Sexta: 08h00 às 18h00</li>
                    <li>🕒 Sábado: 08h00 às 12h00</li>
                  </strong>
                  <Box className={classes.containerHours}>
                    <p>
                      <strong>
                        📄 Documentos necessários para retirada na central:
                      </strong>{" "}
                      Documento com foto
                    </p>
                  </Box>
                </Box>

                <Box className={classes.containerButton}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.primary.dark,
                      color: "#FFF",
                      fontSize: "18px",
                      marginTop: "30px",
                    }}
                    className={classes.button}
                    disabled={disabled || loading}
                    onClick={handleSubmit}
                  >
                    Continuar
                  </Button>
                </Box>
              </Box>
            </Box>
            <DesktopInformations
              setOpenSummary={setOpenSummary}
              holderName={data?.name ?? ""}
              holderCpf={data?.cpf ?? ""}
              holderPhone={data?.phone ?? ""}
              holderEmail={data?.email ?? ""}
              holderAddress={data?.address.street ?? ""}
              holderCep={data?.address.zip_code ?? ""}
              dependents={String(dependentsSelected) ?? ""}
              value={valueSelected ?? ""}
              paymentType={payment?.payment_type}
              planNameSelected={planNameSelected ?? ""}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";

interface IDependent {
  name: string;
  phone: string;
  email: string;
  cpf: string;
  birthday: string;
  address: {
    zip_code: string;
    state: string;
    city: string;
    street: string;
    number: string;
    neighborhood: string;
    complement: string;
  };
}

interface IPlanContext {
  planNameSelected: string;
  setPlanNameSelected: React.Dispatch<React.SetStateAction<string>>;
  dependentsSelected: IDependent[];
  setDependentsSelected: React.Dispatch<React.SetStateAction<IDependent[]>>;
  valueSelected: string;
  setValueSelected: React.Dispatch<React.SetStateAction<string>>;
  paymentType: string;
  setPaymentType: React.Dispatch<React.SetStateAction<string>>;
}

const PlanContext = createContext<IPlanContext | undefined>(undefined);

export const PlanProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [planNameSelected, setPlanNameSelected] = useState<string>("");
  const [dependentsSelected, setDependentsSelected] = useState<IDependent[]>([]);
  const [valueSelected, setValueSelected] = useState<string>("29.90");
  const [paymentType, setPaymentType] = useState<string>("credit_card");

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("selectedPlan") || "{}");

    if (storedData) {
      setPlanNameSelected(storedData.planNameSelected || "");
      setDependentsSelected(storedData.dependents || []);
      setValueSelected(storedData.value || "29.90");
      setPaymentType(storedData.paymentType || "credit_card");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "selectedPlan",
      JSON.stringify({ planNameSelected, dependents: dependentsSelected, value: valueSelected, paymentType })
    );
  }, [planNameSelected, dependentsSelected, valueSelected, paymentType]);

  return (
    <PlanContext.Provider
      value={{
        planNameSelected,
        setPlanNameSelected,
        dependentsSelected,
        setDependentsSelected,
        valueSelected,
        setValueSelected,
        paymentType,
        setPaymentType,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};

export const usePlanContext = (): IPlanContext => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error("usePlanContext must be used within a PlanProvider");
  }
  return context;
};
